<route>
{
  "meta": {
    "permission": [
      "expenses.add_expense", "expenses.change_expense", "expenses.delete_expense", "expenses.view_expense"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <dialExpense :recurrent="false" title="expenses_regular"></dialExpense>
  </v-container>
</template>
<script>
import dialExpense from '../../components/expense/modal.vue'
export default {
  components: {
    dialExpense
  }
}
</script>
